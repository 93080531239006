import { defineStore } from 'pinia';
import type { BasicUser, UserData } from '~/types/users';

export const useUserStore = defineStore('UserStore', {
  state: () => ({
    pendingUserToken: false as boolean,
    userToken: null as string | null,
    userData: null as UserData | null,
    expiration: null as number | null,
    agencyMembers: [] as BasicUser[],
    membersExpire: new Date(Date.now() - 1000 * 10) as Date,
    pendingMembers: false as boolean,
  }),
  getters: {
    getAgencyMembers(): BasicUser[] | null {
      if (this.membersExpire > new Date()) {
        return this.agencyMembers;
      } else {
        this.agencyMembers = [];
        return null;
      }
    },
  },
  actions: {
    updateAgencyMembers(members: BasicUser[]) {
      this.agencyMembers = members;
      this.membersExpire = new Date(Date.now() + 1000 * 60 * 10); // TTL = 10min
    },
    reset() {
      this.pendingUserToken = false;
      this.userToken = null;
      this.userData = null;
      this.expiration = null;
      this.agencyMembers = [];
      this.membersExpire = new Date(Date.now() - 1000 * 10);
      this.pendingMembers = false;
    },
  },
});
