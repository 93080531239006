import jwtDecode from 'jwt-decode';
import type { UserData } from '~/types/users';

export default (forceRefresh?: boolean): Ref<UserData | null> => {
  const userStore = useUserStore();
  const { userToken, userData, expiration, pendingUserToken } =
    storeToRefs(userStore);
  if (
    forceRefresh ||
    (!pendingUserToken.value &&
      (!userToken.value || !expiration.value || expiration.value < Date.now()))
  ) {
    userToken.value = null;
    userData.value = null;
    expiration.value = null;
    pendingUserToken.value = true;

    $fetch(`/api/auth/user`, { headers: useRequestHeaders(['cookie']) })
      .then((token) => {
        userToken.value = token;
        const decoded = jwtDecode(token) as UserData & { exp: number };
        const { exp, ...decodedUserData } = decoded;
        userData.value = decodedUserData;
        expiration.value = exp * 1000;
        pendingUserToken.value = false;
      })
      .catch(async (error) => {
        const supabase = useSupabaseClient();
        await supabase.auth.signOut();
        navigateTo('/login');
        console.log('user fetch error', error);
        throw error;
      });
  }
  return userData;
};
